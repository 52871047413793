import React from "react";
import { Link } from "react-router-dom";
import "./OldLouieFooter.css"; // Optional: Add CSS for styling

const OldLouieFooter = () => {
  return (
    <section className="old-footer" style={{ paddingTop: "15px" }}>
        <div className="footer-row">
          <div className="dflex">
            {/* Left side */}
            <div className="col-md-6 text-md-start text-center">
              Copyright &copy; Louie Voice 2022
            </div>
            {/* Right side */}
            <div className="col-md-6 text-md-end text-center mt-2 mt-md-0">
              <Link to="/privacy">Privacy Policy</Link> |{" "}
              <Link to="/terms">Terms of Use</Link>
            </div>
          </div>
        </div>
    </section>
  );
};

export default OldLouieFooter;
