import React from 'react'
import './Home.css'
import Section1 from '../sections/Section1'
import Section2 from '../sections/Section2'
import Section3 from '../Section3'
import Section4 from '../sections/Section4'
import GlobalLanguage from '../sections/GlobalLanguage'
import MyCreator from '../sections/MyCreator'
import Section7 from '../sections/Section7'
import Section5 from '../sections/Section5'
import LouieInMedia from '../sections/LouieInMedia'
import LouieCustomers from '../sections/LouieCustomers'
import Section7a from '../sections/Section7a'
import Footer from '../sections/Footer'
import ContactUs from '../ContactUs'
import Section3New from '../Section3_new'
import Section3v3 from '../Section3v3'
import Section3v4 from '../sections/Section3v4'
import MobileContactMenu from '../MobileContactMenu'
import FooterV1 from '../sections/FooterV1'
import Inclusion from '../sections/Inclusion'
import FooterV2 from '../sections/FooterV2'

export const Home = () => {
  return (
    <div>
      <Section1/>
      <Section2/>
      {/* <div className='gap-container'></div> */}
      <Section3v4/>


      {/* <Section3v3/> */}
      {/* <Section3New/> */}
      {/* <Section3/> */}


      <Section4/>
      {/* <Section5/> */}
      <div className="gradient-separator"></div>
      <GlobalLanguage/>
      <Inclusion/>
      {/* <Section7a/> */}


      {/* <Section7/> */}
      {/* <LouieCustomers/> */}
      {/* <LouieInMedia/> */}
      <MyCreator/>
      {/* <Footer/> */}
      {/* <FooterV1/> */}
      <FooterV2/>
      <ContactUs/>
    </div>
  )
}


