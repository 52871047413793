// import React from 'react'
// import './Navbar.css'
// export const Navbar = () => {
//   return (
//     <>
//         {/* <div className='nav-container' style={{width: 1440, height: 65, background: 'rgba(255, 255, 255, 0.60)', borderTopLeftRadius: 20, borderTopRightRadius: 20, backdropFilter: 'blur(50px)'}} /> */}
//         <nav className="navbar">
//             <div className="navbar-logo">
//                 <img src="/images/louie_logo_hd_1.png" alt="Louie Voice Logo" />
//                 <span>Louie Voice</span>
//                 {/* <div style={{textAlign: 'center', color: '#080E57', fontSize: 24, fontFamily: 'Open Sans', fontWeight: '700', lineHeight: 37, wordWrap: 'break-word'}}>Louie Voice</div> */}
//             </div>
//             <ul className="navbar-links">
//                 <li><a href="#">Home</a></li>
//                 <li><a href="#">About Us</a></li>
//                 <li><a href="#">Use Cases</a></li>
//                 <li><a href="#">Languages</a></li>
//                 <li><a href="#">Join Louie Team</a></li>
//                 {/* <li className="contact-btn"> <a href='#'>Contact Us</a> </li>  */}
//                 <li className="contact-btn">Contact Us</li>
//             </ul>
//         </nav>
//     </>
//   )
// }



import React, { useCallback, useEffect, useRef, useState } from 'react';
import './NavbarDev.css'
import { Link, useNavigate } from 'react-router-dom';
export const NavbarDev = () => {
    const [showContact, setShowContact] = useState(false);
    const contactRef = useRef(null);
    const buttonRef = useRef(null); // Ref for the button
    const hamburgerRef = useRef(null); // Ref for the button
    const [menuOpen, setMenuOpen] = useState(false);
    const menuRef = useRef(null);
    const buttonRefMobile = useRef(null);

    // const handleScroll = (event,id) => {
    //   event.preventDefault();
    //   const section = document.getElementById(id);
    //   if (section) {
    //     section.scrollIntoView({ behavior: "smooth" });
    //   }
    // };
    
    const navigate = useNavigate();

  const handleScroll = (event, id) => {
    event.preventDefault();
    setMenuOpen(false)
    // Navigate to home first if not already on home
    if (window.location.pathname !== "/") {
      navigate("/", { replace: true });
      
      // Delay scrolling to ensure navigation is completed
      setTimeout(() => {
        scrollToSection(id);
      }, 100);
    } else {
      scrollToSection(id);
    }
  };

  const scrollToSection = (id) => {
    const section = document.getElementById(id);
    if (section) {
      section.scrollIntoView({ behavior: "smooth" });
    }
  };

  // const scrollToSection = (id) => {
  //   const section = document.getElementById(id);
  //   if (section) {
  //     const yOffset = -70; // Adjust this value as needed for mobile fine-tuning
  //     const y = section.getBoundingClientRect().top + window.scrollY + yOffset;
  
  //     window.scrollTo({ top: y, behavior: "smooth" });
  //   }
  // };

    const handleClickOutside = useCallback((event) => {
      // console.log('inside hadle click outside')
      // console.log(!contactRef.current?.contains(event.target))
      // console.log(!buttonRef.current?.contains(event.target))
        setTimeout(() => {
          if (
              contactRef.current &&
              !contactRef.current.contains(event.target) &&
              buttonRef.current &&
              !buttonRef.current.contains(event.target)
          ) {
              setShowContact(false);
          }
          
        }, 0); // Allows state to update first

        // console.log(!menuRef.current?.contains(event.target))
        // console.log(!hamburgerRef.current?.contains(event.target))

        if (
          menuRef.current &&
          !menuRef.current.contains(event.target) &&
          hamburgerRef.current &&
          !hamburgerRef.current.contains(event.target)
        ) {
          // console.log('menu ref')
          setMenuOpen(false);
        }
        // setShowContact(false)
    }, []);
    // console.log(showContact)
    // console.log('menuopen',menuOpen)
    useEffect(() => {
        if (showContact) {
          // console.log('event added due to show contact')
            document.addEventListener("mousedown", handleClickOutside);
        } else {
            document.removeEventListener("mousedown", handleClickOutside);
        }
        if (menuOpen) {
          document.addEventListener("mousedown", handleClickOutside);
        } 
        // else {
        //   document.removeEventListener("mousedown", handleClickOutside);
        // }
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [showContact, handleClickOutside,menuOpen]);


    return (
        <>
            <nav className="navbar">
            <div className="navbar-logo" onClick={(event)=>handleScroll(event,'home')}>
                    <img src="/images/louie_logo_hd_1.png" alt="Louie Voice Logo" />
                    <span>Louie Voice</span>
                </div>
                {/* Hamburger Icon */}
                {/* <button className="menu-toggle" ref={hamburgerRef} onClick={() => {console.log('menu clicked');setMenuOpen(!menuOpen)}}>
                  ☰
                </button> */}
            <div className="navbar-items">
                <div className="navbar-logo-mobile" onClick={(event)=>handleScroll(event,'home')}>
                    <img src="/images/louie_logo_hd_1.png" alt="Louie Voice Logo" />
                    <span>Louie Voice</span>
                </div>
                <button className="menu-toggle" ref={hamburgerRef} onClick={() => {setMenuOpen(!menuOpen)}}>
                  ☰
                </button>
            </div>
                {/* <ul className="navbar-links"> */}
                <ul className={`navbar-links ${menuOpen ? "active" : ""}`} ref={menuRef}>
                    <li><Link to="/" onClick={(event)=>handleScroll(event,'home')}>Home</Link></li>
                    {/* <li><a href="/" onClick={(event)=>handleScroll(event,'home')}>Home</a></li> */}
                    <li><Link href="/" onClick={(event)=>handleScroll(event,'about-us')}>About Us</Link></li>
                    <li><Link href="/" onClick={(event)=>handleScroll(event,'inclusion')}>Use Cases</Link></li>
                    <li><Link href="" onClick={(event)=>handleScroll(event,'language-section')} >Languages</Link></li>
                    {/* <li><a href="#">Join Louie Team</a></li> */}
                    <button className="contact-btn" onClick={() => {setShowContact(!showContact)}} ref={buttonRef} >Contact Us</button>
                    {/* <li><Link className="contact-btn-li" onClick={() => setShowContact(!showContact)} ref={buttonRefMobile} >Contact Us</Link> </li> */}
                </ul>
            </nav>
            {showContact && (
              <>
                <div className="contact-dropdown"  ref={contactRef}>
                    <div className="nav-contact-icons">
                      <a className='mobile-contact-us' onClick={()=>setShowContact(false)} >Contact Us</a>
                      <hr className='mobile-contact-us'/>
                      <a href="https://www.linkedin.com/in/pramit-bhargava-1860668/" target="_blank" rel="noopener noreferrer" onClick={()=>setShowContact(false)} >
                        <svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 17 17" fill="none">
                          <path d="M3.80603 17H0.280804V5.65022H3.80603V17ZM2.04152 4.10201C0.914509 4.10201 0 3.16853 0 2.04152C0 1.50007 0.215088 0.980806 0.597947 0.597947C0.980806 0.215088 1.50007 0 2.04152 0C2.58296 0 3.10223 0.215088 3.48509 0.597947C3.86795 0.980806 4.08304 1.50007 4.08304 2.04152C4.08304 3.16853 3.16853 4.10201 2.04152 4.10201ZM16.9962 17H13.4786V11.475C13.4786 10.1583 13.452 8.46964 11.6458 8.46964C9.81295 8.46964 9.53214 9.90022 9.53214 11.3801V17H6.01071V5.65022H9.39174V7.19844H9.44107C9.91161 6.3067 11.0614 5.36562 12.7766 5.36562C16.3435 5.36562 17 7.71451 17 10.7654V17H16.9962Z" fill="#080E57"/>
                        </svg> LinkedIn
                      </a>
                      <hr/>
                      <a href="mailto:hello@louievoice.com" onClick={()=>setShowContact(false)}>
                      <svg xmlns="http://www.w3.org/2000/svg" width="20" height="15" viewBox="0 0 20 15" fill="none">
                        <path d="M1.875 0C0.839844 0 0 0.839844 0 1.875C0 2.46484 0.277344 3.01953 0.75 3.375L9.25 9.75C9.69531 10.082 10.3047 10.082 10.75 9.75L19.25 3.375C19.7227 3.01953 20 2.46484 20 1.875C20 0.839844 19.1602 0 18.125 0H1.875ZM0 4.375V12.5C0 13.8789 1.12109 15 2.5 15H17.5C18.8789 15 20 13.8789 20 12.5V4.375L11.5 10.75C10.6094 11.418 9.39062 11.418 8.5 10.75L0 4.375Z" fill="#080E57"/>
                      </svg> Email
                      </a>
                    </div>
                </div>
              </>
            )}
        </>
    );
};

