import React from 'react'
import { Route, Routes } from 'react-router-dom'
import { Home } from './pages/Home'
import OldLouiePrivacyPolicy from './pages/OldLouiePrivacyPolicy'
import OldLouieTermsAndContition from './pages/OldLouieTermsAndContition'

const AllRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/privacy" element={<OldLouiePrivacyPolicy />} />
      <Route path="/terms" element={<OldLouieTermsAndContition />} />
    </Routes>
  )
}

export default AllRoutes
