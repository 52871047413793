import React, { useState } from "react";
import "./ContactUs.css";

const ContactUs = () => {
  const [isHovered, setIsHovered] = useState(false);
  const [hoverTimeout, setHoverTimeout] = useState(null);
  const handleMouseEnter = () => {
    const timeout = setTimeout(() => {
      setIsHovered(true);
    }, 300); // 300ms delay like in Figma

    setHoverTimeout(timeout);
  };

  const handleMouseLeave = () => {
    clearTimeout(hoverTimeout);
    setIsHovered(false);
  };

  return (
    <div
      className={`contact-container ${isHovered ? "expanded" : ""}`}
      // onMouseEnter={() => setIsHovered(true)}
      // onMouseLeave={() => setIsHovered(false)}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      {!isHovered ? (
        <div className="contact-tab">Contact Us</div>
      ) : (
        <div className="contact-expanded">
          <div className="contact-tab contact-tab1">Contact Us</div>
          <div className="contact-icons">
            <a href="https://www.linkedin.com/in/pramit-bhargava-1860668/" target="_blank" rel="noopener noreferrer">
              <img src="/images/linkedin_icon.png"  alt="LinkedIn"/>
            </a>
            <a href="mailto:hello@louievoice.com">
              <img src="/images/email_icon.png"  alt="Email"/>
            </a>
          </div>
        </div>
      )}
    </div>
  );
};

export default ContactUs;
