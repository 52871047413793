import React from "react";
import "./MyCreator.css";

const MyCreator = () => {
  return (
    <>
    <div className="container" >
      <h2 id="about-us">My Creator</h2>
      <div className="my-creater" >
        <div className="image">
          <img src="/images/pramit.jpg" alt="Louie Creator"/>
        </div>

        <div className="my-creator-text">
          <p>
          Pramit (Louie Founder), built Louie to solve his own personal challenge of vision impairment, driven by a simple thought- " why can't I control an app end to end with just my voice commands".
          </p>
          <p>
          Pramit is a graduate from India's leading Tech and Management Institutes (IIT, IIM). He has worked with Global MNCs such as Unilever, Motorola, Quest Diagnostics.
          </p>
        </div>
      </div>
    </div>

    </>
  );
};

export default MyCreator;
