import React, { useEffect, useRef, useState } from "react";
import "./Section3v4.css";

const Section3v4 = () => {
    const [isVideoVisible, setIsVideoVisible] = useState(false);
    const [videoSrc, setVideoSrc] = useState("");
    const [hoveredPhone, setHoveredPhone] = useState(null);
    const videoRef = useRef(null);
    // const louie='https://firebasestorage.googleapis.com/v0/b/louievoicewebsite.appspot.com/o/Uber%20Videos%2FLV%20-%20Uber%20.mp4?alt=media&token=56972954-c981-436f-b314-ae83329bce99'
    // const google='https://firebasestorage.googleapis.com/v0/b/louievoicewebsite.appspot.com/o/Uber%20Videos%2FGA%20-%20Uber.mp4?alt=media&token=8a66b9e9-ef78-4020-8d36-fc5aeb953ec8'
    // const siri='https://firebasestorage.googleapis.com/v0/b/louievoicewebsite.appspot.com/o/Uber%20Videos%2FSiri%20-%20Uber%20.mp4?alt=media&token=f10731f1-9d86-42f0-9fe7-bb6ad839ba74'
    
    const louie='https://firebasestorage.googleapis.com/v0/b/louievoicewebsite.appspot.com/o/Uber%20Videos%2FUber%20via%20Louie.mp4?alt=media&token=0cfb70a6-f156-429d-8da3-072799372a3d'
    const google='https://firebasestorage.googleapis.com/v0/b/louievoicewebsite.appspot.com/o/Uber%20Videos%2FUber%20via%20GA%20.mp4?alt=media&token=724d4c5f-dc13-463e-b94b-560bfe9594cf'
    const siri='https://firebasestorage.googleapis.com/v0/b/louievoicewebsite.appspot.com/o/Uber%20Videos%2FUber%20via%20Siri.mp4?alt=media&token=5e44a2d8-2643-4560-8fb2-e3cf13c46023'
    
    const videoRefs=useRef([])
    const sectionRefs = useRef([]);
// mobile changes
    useEffect(() => {
      const observers = [];
  
      sectionRefs.current.forEach((section, index) => {
        if (!section) return; // Skip if ref is not assigned
  
        const observer = new IntersectionObserver(
          ([entry]) => {
            if (videoRefs.current[index] && !entry.isIntersecting) {
              videoRefs.current[index].pause(); // Pause video when out of view
            }
          },
          { threshold: 0.1 }
        );
  
        observer.observe(section);
        observers.push(observer);
      });
  
      return () => {
        observers.forEach((observer, index) => {
          if (sectionRefs.current[index]) {
            observer.unobserve(sectionRefs.current[index]);
          }
        });
      };
    }, []);
  

// Play video after state updates
// useEffect(() => {
//   if (isVideoVisible && videoRef.current) {
//     const playPromise = videoRef.current.play();
//     if (playPromise !== undefined) {
//       playPromise.catch((error) => console.error("Playback failed:", error));
//     }
//   }
// }, [isVideoVisible]);

// changes for mobile view and videoRef converted to videoRefs
useEffect(() => {
  videoRefs.current.forEach((video, index) => {
    if (isVideoVisible[index] && video) {
      const playPromise = video.play();
      if (playPromise !== undefined) {
        playPromise.catch((error) =>
          console.error(`Playback failed for video ${index}:`, error)
        );
      }
    }
  });
}, [isVideoVisible]);

const handleHover = (src,phoneType) => {
  //   setVideoSrc(src);
  //   setIsVideoVisible(true);


// setVideoSrc(src);
// setHoveredPhone(phoneType);
};

const handleClick=(src,phoneType)=>{
  setVideoSrc(src);
  setIsVideoVisible(true);
  setHoveredPhone(phoneType);
}

// const handleMouseLeave = () => {
  //   setIsVideoVisible(false);
//   if (videoRef.current) {
  //     videoRef.current.pause();
  //     videoRef.current.currentTime = 0; // Reset video on exit
  //   }
  // };
  
  // changes for mobile view and videoRef converted to videoRefs
  const handleMouseLeave = () => {
    setIsVideoVisible(new Array(videoRefs.current.length).fill(false)); // Reset visibility for all videos
  
    videoRefs.current.forEach((video, index) => {
      if (video) {
        video.pause();
        video.currentTime = 0; // Reset video on exit
      }
    });
  };
  
return (
    <section className="section3">
      <h2 className="section3-title">
        Can Google Assistant, Siri do
        <br />
        <span>what Louie can?</span>
      </h2>

      <div className="section3-content">
        {/* Louie Voice */}
        <div className="assistant">

          <svg className="louie-svg" xmlns="http://www.w3.org/2000/svg" width="100" height="101" viewBox="0 0 100 101" fill="none">
                <path d="M100 50.3906C100 78.0049 77.6142 100.391 50 100.391C22.3858 100.391 0 78.0049 0 50.3906C0 22.7764 22.3858 0.390625 50 0.390625C77.6142 0.390625 100 22.7764 100 50.3906ZM18.4729 50.3906C18.4729 67.8026 32.588 81.9178 50 81.9178C67.412 81.9178 81.5271 67.8026 81.5271 50.3906C81.5271 32.9787 67.412 18.8635 50 18.8635C32.588 18.8635 18.4729 32.9787 18.4729 50.3906Z" fill="#080E57"/>
          </svg>
          <span className="assistant-label louie">LOUIE VOICE</span>
          <div
            className="phone"
            onClick={()=>handleClick("/videos/louie.mp4", "louie")}
            onMouseEnter={() => handleHover("/videos/louie.mp4", "louie")}
            onMouseLeave={handleMouseLeave}
            ref={(el) => (sectionRefs.current[0] = el)}
          >
            {hoveredPhone === "louie" && (
              // <video ref={videoRef} src={louie} autoPlay='true'  controls  playsInline controlsList="nodownload nofullscreen noremoteplayback"
              <video ref={(el) => (videoRefs.current[0] = el)} src={louie} autoPlay='true'  controls  playsInline controlsList="nodownload nofullscreen noremoteplayback"
              disablePictureInPicture
              />
            )}
            {hoveredPhone!=='louie'
              && <> 
                    <p>Uber Booking with Louie Voice</p>
                    <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30" fill="none">
                        <path d="M0 15C0 11.0218 1.58035 7.20644 4.3934 4.3934C7.20644 1.58035 11.0218 0 15 0C18.9782 0 22.7936 1.58035 25.6066 4.3934C28.4196 7.20644 30 11.0218 30 15C30 18.9782 28.4196 22.7936 25.6066 25.6066C22.7936 28.4196 18.9782 30 15 30C11.0218 30 7.20644 28.4196 4.3934 25.6066C1.58035 22.7936 0 18.9782 0 15ZM11.0332 8.61914C10.5879 8.86523 10.3125 9.33984 10.3125 9.84375V20.1562C10.3125 20.666 10.5879 21.1348 11.0332 21.3809C11.4785 21.627 12.0176 21.6211 12.457 21.3516L20.8945 16.1953C21.3105 15.9375 21.5684 15.4863 21.5684 14.9941C21.5684 14.502 21.3105 14.0508 20.8945 13.793L12.457 8.63672C12.0234 8.37305 11.4785 8.36133 11.0332 8.60742V8.61914Z" fill="white"/>
                    </svg>
                </>
            }
          </div>
        </div>

        {/* Google Assistant */}
        <div className="assistant">
        <img className="google-img" src="/images/Google_Assistant_logo.svg-removebg-preview 1.png" alt="gogle-assistant-logo" />
          <span className="assistant-label google">GOOGLE ASSISTANT</span>
          <div
            className="phone"
            onClick={() => handleClick("/videos/google.mp4", "google")}
            onMouseEnter={() => handleHover("/videos/google.mp4", "google")}
            onMouseLeave={handleMouseLeave}
            ref={(el) => (sectionRefs.current[1] = el)}
          >
            {hoveredPhone === "google" && (
              // <video ref={videoRef} src={google} autoPlay controls playsInline controlsList="nodownload" disablePictureInPicture />
              <video ref={(el) => (videoRefs.current[1] = el)} src={google} autoPlay controls playsInline controlsList="nodownload" disablePictureInPicture />
            )}
            {hoveredPhone!=='google' && 
                <> 
                    <p>Uber Booking with Google Assistant</p>
                    <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30" fill="none">
                        <path d="M0 15C0 11.0218 1.58035 7.20644 4.3934 4.3934C7.20644 1.58035 11.0218 0 15 0C18.9782 0 22.7936 1.58035 25.6066 4.3934C28.4196 7.20644 30 11.0218 30 15C30 18.9782 28.4196 22.7936 25.6066 25.6066C22.7936 28.4196 18.9782 30 15 30C11.0218 30 7.20644 28.4196 4.3934 25.6066C1.58035 22.7936 0 18.9782 0 15ZM11.0332 8.61914C10.5879 8.86523 10.3125 9.33984 10.3125 9.84375V20.1562C10.3125 20.666 10.5879 21.1348 11.0332 21.3809C11.4785 21.627 12.0176 21.6211 12.457 21.3516L20.8945 16.1953C21.3105 15.9375 21.5684 15.4863 21.5684 14.9941C21.5684 14.502 21.3105 14.0508 20.8945 13.793L12.457 8.63672C12.0234 8.37305 11.4785 8.36133 11.0332 8.60742V8.61914Z" fill="white"/>
                    </svg>
                </>
            }
          </div>
        </div>

        {/* Siri */}
        <div className="assistant">
          <img className="siri-img" src="/images/siri 1.png" alt="siri-image" />
          <span className="assistant-label siri">SIRI</span>
          <div
            className="phone"
            onClick={() => handleClick("/videos/siri.mp4", "siri")}
            onMouseEnter={() => handleHover("/videos/siri.mp4", "siri")}
            onMouseLeave={handleMouseLeave}
            ref={(el) => (sectionRefs.current[2] = el)}
          >
            {hoveredPhone === "siri" && (
              // <video ref={videoRef} src={siri} autoPlay controls playsInline controlsList="nodownload" disablePictureInPicture />
              <video ref={(el) => (videoRefs.current[2] = el)} src={siri} autoPlay controls playsInline controlsList="nodownload" disablePictureInPicture />
            )}
            {
                hoveredPhone!=='siri' &&
                <> 
                    <p>Uber Booking with Siri</p>
                    <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30" fill="none">
                        <path d="M0 15C0 11.0218 1.58035 7.20644 4.3934 4.3934C7.20644 1.58035 11.0218 0 15 0C18.9782 0 22.7936 1.58035 25.6066 4.3934C28.4196 7.20644 30 11.0218 30 15C30 18.9782 28.4196 22.7936 25.6066 25.6066C22.7936 28.4196 18.9782 30 15 30C11.0218 30 7.20644 28.4196 4.3934 25.6066C1.58035 22.7936 0 18.9782 0 15ZM11.0332 8.61914C10.5879 8.86523 10.3125 9.33984 10.3125 9.84375V20.1562C10.3125 20.666 10.5879 21.1348 11.0332 21.3809C11.4785 21.627 12.0176 21.6211 12.457 21.3516L20.8945 16.1953C21.3105 15.9375 21.5684 15.4863 21.5684 14.9941C21.5684 14.502 21.3105 14.0508 20.8945 13.793L12.457 8.63672C12.0234 8.37305 11.4785 8.36133 11.0332 8.60742V8.61914Z" fill="white"/>
                    </svg>
                </>
            }
          </div>
        </div>
      </div>
    </section>
  );
};

export default Section3v4;

// controlsList="nodownload nofullscreen noremoteplayback"
//   onContextMenu={(e) => e.preventDefault()} 

