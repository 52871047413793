import React from "react";
import "./GlobalLanguage.css";
// import worldMap from "./world-map.svg"; // Replace with the correct path to your SVG file

const GlobalLanguage = () => {
  return (
    <>
      <div className="global-language" id="language-section">
        <h2>Which languages Louie can support?</h2>
        <div className="language-info">
          <span className="number">40</span>
          <div className="text">Global {<br/>} Languages</div>
        </div>
        {/* <img src={worldMap} alt="World Map" className="world-map" /> */}
        <img src='/images/world-map.png' alt="World Map" className="world-map" />
      </div>
    </>
  );
};

export default GlobalLanguage;
