import './App.css';
import { BrowserRouter } from 'react-router-dom';
import AllRoutes from './components/AllRoutes';
import { Navbar } from './components/Navbar';
import { Home } from './components/pages/Home';
import { NavbarDev } from './components/NavbarDev';

function App() {
  return (
    <BrowserRouter>
        {/* <Navbar/> */}
        <NavbarDev/>
        <AllRoutes />
    </BrowserRouter>
  );
}

export default App;
