import React from "react";
import "./Inclusion.css"; // Import the CSS file

const Inclusion = () => {
  return (
    <section className="inclusion-section" id="inclusion">
        {/* <div className="svg-background">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 1440 449"
          fill="none"
          className="background-svg"
        >
          <path
            d="M374.826 80.6599C193.778 -10.3902 53.1775 -7.38649 -10.9212 9.204L-8.16886 444.346L685.553 444.346L1436.05 448.851L1440.12 64.5343C1332.92 135.143 1058.53 312.12 859.767 308.065C611.31 302.996 601.137 194.473 374.826 80.6599Z"
            fill="#D4D7FF" // ✅ Solid color instead of gradient
            fillOpacity="0.5"
          />
        </svg>
      </div> */}
      <div className="svg-background">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 1440 449"
          fill="none"
          className="background-svg"
        >
          <path
            d="M374.826 80.6599C193.778 -10.3902 53.1775 -7.38649 -10.9212 9.204L-8.16886 444.346L685.553 444.346L1436.05 448.851L1440.12 64.5343C1332.92 135.143 1058.53 312.12 859.767 308.065C611.31 302.996 601.137 194.473 374.826 80.6599Z"
            fill="url(#paint0_linear_1084_564)"
            fillOpacity="0.5"
          />
          <defs>
            <linearGradient
              id="paint0_linear_1084_564"
              x1="594.482"
              y1="-78.6445"
              x2="622.675"
              y2="432.3"
              gradientUnits="userSpaceOnUse"
            >
              <stop offset="0.000999756" stopColor="#FBFCFF" />
              <stop offset="0.406" stopColor="#D4D7FF" />
              <stop offset="0.981" stopColor="white" />
            </linearGradient>
          </defs>
        </svg>
      </div>

      <div className="inclusion-container">
        <h2>Can Louie drive Inclusion & Accessibility?</h2>

        <div className="inclusion-content">
          <div className="inclusion-left">
            <h3 className="desktop-view">
              Louie Voice Interface <br /> cuts across
            </h3>
            <h3 className="mobile-view-tag">
              Louie Voice Interface cuts across
            </h3>
          </div>

          <div className="inclusion-right">
            <ul>
              <li>
                <img src="/images/inclusion/G1.png" alt="Disabilities" />
                Disabilities
              </li>
              <li>
                <img src="/images/inclusion/G2.png" alt="Age Groups" />
                Age Groups
              </li>
              <li>
                <img src="/images/inclusion/G3.png" alt="Literacy Level" />
                Literacy Level
              </li>
              <li>
                <img src="/images/inclusion/G4.png" alt="Language Barriers" />
                Language Barriers
              </li>
              <li className="different-order">
                <img src="/images/inclusion/G1.png" alt="Accessibility" />
                Accessibility of a different order
                {/* <p>
                  Louie Voice Interface will understand the User Intent & just
                  get the work done, without the user knowing where the specific
                  menus & buttons are.
                </p> */}
              </li>
              <li>
              <p>
                  Louie Voice Interface will understand the User Intent & just
                  get the work done, without the user knowing where the specific
                  menus & buttons are.
                </p>
              </li>
             
            </ul>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Inclusion;
