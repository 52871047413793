import React from "react";
import "./LouieCustomers.css";

const testimonials = [
  { id: 1, name: "Name Here", company: "Company Name Here", text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit..." },
  { id: 2, name: "Name Here", company: "Company Name Here", text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit..." },
  { id: 3, name: "Name Here", company: "Company Name Here", text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit..." },
  { id: 4, name: "Name Here", company: "Company Name Here", text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit..." },
  { id: 5, name: "Name Here", company: "Company Name Here", text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit..." },
  { id: 6, name: "Name Here", company: "Company Name Here", text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit..." },
];

// Duplicate items for seamless looping
const allTestimonials = [...testimonials, ...testimonials];

const LouieCustomers = () => {
  return (
    <div className="louie-customers">
      <h2>Louie Customers</h2>
      <div className="carousel">
        <div className="carousel-track">
          {allTestimonials.map((testimonial, index) => (
            <div key={index} className="testimonial-card">
              <span className="quote-icon">❝</span>
              <div className="testimonial-content">
                <h3>{testimonial.name}</h3>
                <h4>{testimonial.company}</h4>
                <p>{testimonial.text}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default LouieCustomers;
