import React from "react";
import "./Section1.css";

const Section1 = () => {
  return (
    <section className="section1" id="home">
      <div className="section1-content">
        <h1 className="mobile-h1">Hi! I’m Louie.</h1>
        <div className="section1-image">
          {/* <img src="/images/intro_mobile@2x1.png" alt="Louie App" /> */}
          <img src="/images/louie_intro_mobile.png" alt="Louie App" />
          {/* <img src="/images/intro_mobile_main.png" alt="Louie App" /> */}
        </div>
        <div className="section1-text">
          <h1 className="desktop-h1">Hi! I’m Louie.</h1>
          {/* <h2>
            I’m an <span className="highlight">AI Driven</span> Voice Interface for <br /> Apps & Websites
          </h2> */}

          {/* Desktop view */}
          <h2 className="full-title desktop-only">
            I’m an <span className="highlight">AI Driven</span> Voice Interface for <br />
            Apps & Websites
          </h2>

          {/* Mobile view */}
          {/* <div className="mobile-only">
            <h2>I’m an <span className="highlight">AI Driven</span></h2>
            <h2>Voice Interface for</h2>
            <h2 className="apps-title">Apps & Websites</h2>
          </div> */}
          <div className="mobile-only">
            <h2 className="m-0 leading-tight text-xl">
              I’m an <span className="highlight">AI Driven</span>
            </h2>
            <h2 className="m-0 leading-tight text-xl">Voice Interface for</h2>
            <h2 className="apps-title m-0 leading-tight text-xl">Apps & Websites</h2>
          </div>


          <p>
          Louie SDK can voice enable any app or website in the world, be it Android or iOS.
          </p>
          <p>
          Louie Voice SDK provides a multilingual voice interface, enabling a user to do end-to-end transactions within apps & websites, with just voice commands.
          </p>
          <p>Highly versatile, works across sectors...
          Banking, Taxi Hailing, Food Ordering, Healthcare, E-Commerce, Insurance, Payments...</p>
        </div>
      </div>
    </section>
  );
};


export default Section1;
