import React from "react";
import "./FeatureCard.css";

const FeatureCard = ({ icon, title, description, extraClass }) => {
  // extraClass is for if you want to do diff styling ac to need
  return (
    <div className={`feature-card ${extraClass}`}>
      <div className={`feature-icon ${extraClass}`}>
        {/* <img src={icon} alt={title} /> */}
        {icon}
      </div>
      <h3>{title}</h3>
      <p>{description}</p>
    </div>
  );
};

export default FeatureCard;
