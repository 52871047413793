import React from "react";
// import '../css/old_louie_voice.css'
import '../css/OldLouiePrivacyPolicy.css'
import OldLouieFooter from "../sections/OldLouieFooter";
const OldLouiePrivacyPolicy = () => {
  return (
    <>
      <section className="innerPages">
        <div className="row">
            <div className="col-md-12">
                <h2>Visioapps Technology Pvt. Ltd. Privacy Policy</h2>
                <h2><span>Effective: February 8, 2019 Updated: Sep 6, 2021</span></h2>


                <h3>Introduction</h3>
                <ul>
                    <li>This privacy statement describes how Visioapps Technology Pvt. Ltd. collects and uses the personal information you provide, through its app – LOUIE VOICE ASSISTANT and website <a href="https://louievoice.com/">www.louievoice.com</a></li>
                    <li>When you download or use the App, you consent to the collection, storage, use, disclosure and other uses of your Personal Information (meaning, any information which may potentially allow your identification with reasonable means) as described in this Privacy Policy. If we combine Personal Information with anonymous information about you, the combined information will be treated as Personal Information for as long as it remains combined.</li>
                </ul>


                <h3>WHAT INFORMATION WE COLLECT</h3>
                <p>The Apps collect the following information:</p>
                <ul>
                    <li>The App store and processes the text transcriptions of your voice commands, which may include your call history (including calling party phone number, called party phone number, date, time and Short Message Service (SMS) messages, email addresses and correspondence, and any other voice commands in the app.</li>
                    <p><strong>This information is sent to our servers in order to decipher, analyze and adapt to your voice commands, and in order to improve our services, such as our voice recognition technology. We will not make use of this information for any other purposes.</strong></p>
                    <li><strong>Your contact list,</strong>which is used to improve the interpretation of your related voice commands, and in order to enable you to share content and features with your contacts.</li>
                    <li><strong>You contact information –</strong>in the event you decide to directly volunteer this information to Visioapps Technology Pvt. Ltd., which may include your name, and email address.</li>
                    <li><strong>Log data and device information –</strong>we automatically record and collect certain information from or about your device when you install or use the Apps. This includes usage activity about how you interact with the Apps and web activity, such as what content or web pages you viewed, and which areas of our application you visited, information about your operating system version, IP address, GCM Registration ID, device settings, hardware information, device approximate location, mobile operator or internet service provider information, browser type, language preferences, time zone, domains that referred you to our app (for example the HTTP referrer and URL) and the duration of your visits, your actions on these visits (for example clicks or views on content on the domain, or interactions with social media features). We may also automatically collect your device geo-location. When you are using our Apps, we also automatically record your Google Advertising ID (if you are using an Android device). The Google Advertising ID is an anonymous identifier, provided by Google Play services. If your device has an Advertising ID, we collect and use it for advertising and user analytics purposes. By downloading or using the Apps, you explicitly agree that we may associate your Advertising ID with your applicable persistent device identifier. This will facilitate our ability to improve your personalized experience. Further, we may use other persistent identifiers for non-advertising purposes. If your device does not have Advertising ID, we will use other persistent identifiers. The information collected may be also stored on your device.</li>
                    <li><strong>Location –</strong> some parts of this app make use of location-based data. If you allow these services, we will collect information about the Wi-Fi routers closest to you and the cell IDs of the towers closest to you.</li>
                    <p>We use this information to provide the below location-based services, not to identify you:</p>
                    <li>Provide you local search results.</li>
                    <li>Map routes and provide directions to locations that you specify.</li>
                    <li>Likewise, these services may use the Google Maps API whose privacy policy and terms of service are available here and here, respectively, and may be amended by Google from time to time.</li>
                </ul>
                

                <h3>What Permissions we request:</h3>
                <ul>
                    <b>Device & app history</b>
                    <li>retrieve running apps</li>
                    <b>Identity</b>
                    <li>find accounts on the device</li>
                    <b>Contacts</b>
                    <li>find accounts on the device</li>
                    <li>read your contacts</li>
                    <li>modify your contacts</li>
                    <b>Location</b>
                    <li>approximate location (network-based)</li>
                    <li>precise location (GPS and network-based)</li>
                    <b>Phone</b>
                    <li>directly call phone numbers</li>
                    <li>read phone status and identity</li>
                    <li>read call log</li>
                    <li>write call log</li>
                    <b>Photos / Media / Files</b>
                    <li>read the contents of your USB storage</li>
                    <li>modify or delete the contents of your USB storage</li>
                    <b>SMS</b>
                    <li>send SMS messages</li>
                    <li>edit your text messages (SMS or MMS)</li>
                    <li>read your text messages (SMS or MMS)</li>
                    <li>receive text messages (MMS)</li>
                    <li>receive text messages (SMS)</li>
                    <b>Storage</b>
                    <li>read the contents of your USB storage</li>
                    <li>modify or delete the contents of your USB storage</li>
                    <b>Microphone</b>
                    <li>record audio</li>
                    Wi-Fi connection information
                    <li>view Wi-Fi connections</li>
                    Device ID & call information
                    <li>read phone status and identity</li>
                    <b>Other</b>
                    <li>receive data from Internet</li>
                    <li>view network connections</li>
                    <li>pair with Bluetooth devices</li>
                    <li>access Bluetooth settings</li>
                    <li>connect and disconnect from Wi-Fi</li>
                    <li>disable your screen lock</li>
                    <li>expand/collapse status bar</li>
                    <li>full network access</li>
                    <li>change your audio settings</li>
                    <li>run at startup</li>
                    <li>set wallpaper</li>
                    <li>draw over other apps</li>
                    <li>control vibration</li>
                    <li>prevent device from sleeping</li>
                    <li>modify system settings</li>
                    <li>set an alarm</li>
                    <li>install shortcuts</li>
                </ul>

                <h3>HOW DO WE USE THE INFORMATION WE COLLECT?</h3>
                <ul>
                    <li>
                        <b
                          >Operate your phone via voice commands, including understanding your verbal instructions based on your context.
                        </b>
                      </li>
                      <li>
                        <b
                          >Improve and personalize your experience in our Apps, including through displaying content based upon your interests.</b
                        >
                      </li>
              
                      <li>
                        <b>Surveys and research –</b> from time to time, we conduct surveys or test features in the Apps, and analyze the information we have to develop, evaluate and improve these features.
                      </li>
                      <li>
                        <b>Service-related updates –</b>we use the information that we have to let you know about our policies or important notifications and updates about our Apps. For example, if you have provided us with your email address, we may use it to alert you about upcoming updates.
                      </li>
                      <li>
                        <b>Communication –</b> we use the information that we have to communicate with you – for example, we will use your email address to respond to your queries or feedback.
                      </li>
                      <li>
                        <b>Protecting rights –</b> we will also use and disclose your information (including your Personal Information) to investigate violations and enforce our policies, and as required by law, regulation or other governmental authority, or to comply with a subpoena or similar legal process, when we believe in good faith that disclosure is necessary to protect our rights, protect your safety or the safety of others, investigate any fraud, security or technical issues, or respond to a government request. We also reserve the right to disclose any information in case we believe, in good faith, that such disclosure is appropriate or necessary in order to enforce our policies, take precautions against liabilities, investigate and defend ourselves against any third-party claims or allegations, protect the security or integrity of the Apps and our servers and protect the rights and property of Visioapps Technology Pvt. Ltd., its users and/or others.
                      </li>
                      <li>
                        <b>Audio -</b> The microphones are used to hear commands spoken by you in order to provide you with the best services. We may use third-party providers for eg. google speech to text etc. for processing/execution of the relevant task.
                      </li>
                </ul>

                <h3>DATA SHARING</h3>
                <ul>
                      <li>
                        We share your information that we collect (including your Personal Information) for the purposes listed above with our partners and other trusted businesses and individuals that provide us, or to which we provide a variety of services, such as developers and service providers who process such information for us, so they can help us provide and improve our services. By using the App, you acknowledge and agree that these trusted third parties are authorized to use your Personal Information only as necessary to provide these services.
                      </li>
                      <li>
                        Please note that our businesses, as well as our trusted partners and service providers, are located around the world. Any information that we collect (including your Personal Information) is stored and processed in various jurisdictions around the world (including in the United States), for the purposes detailed in this Privacy Policy. By using or accessing the Apps, you consent to your information being collected, used, and transferred as disclosed herein. The data protection and other laws of these jurisdictions may not be as comprehensive as those in your country – but we will accord by the terms of this Privacy Policy.
                      </li>
                      <li>
                      We may share or transfer your information (including Personal Information) if we enter into a business transaction such as a merger, acquisition, reorganization, bankruptcy, or sale of some or all of our assets. Any party that acquires our assets as part of such a transaction may continue to use your information in accordance with the terms of this Privacy Policy.
                      </li>
                      <li>
                      We may also share your information (including your Personal Information) as required by law, such as to comply with a subpoena, or similar legal process, when we believe in good faith that disclosure is necessary to protect our rights, protect your safety or the safety of others, investigate fraud, or respond to a government request.
                      </li>
                    </ul>
              
                    <h3>CHILDREN PRIVACY</h3>
                    <ul>
                      <li>
                      We do not knowingly collect or solicit Personal Information from anyone under the age of 13 or knowingly allow such persons to register as Users. By accessing or using the App, you certify to us that you are at least 13 years old. If you are under the age of 13, please do not send any information about yourself to us. In the event that we learn that we have collected Personal Information from a child under age of 13 without verification of parental consent, we will delete that information as quickly as possible. If you believe that we might have any information from or about a child under the age of 13, then please contact us at 
                        <a href="mailto:pramit@louievoice.com">pramit@louievoice.com</a>
                      </li>
                    </ul>

                    <h3>RETENTION</h3>
                    <ul>
                        <li>
                        We will retain your Personal Information for as long as needed to fulfill our business operations.
                        </li>
                        <li>
                        We will also retain and use your information as necessary to comply with our legal obligations, resolve disputes, and enforce our agreements.
                        </li>
                    </ul>

                    
                    <h3>SECURITY</h3>
                    <ul>
                        <li>
                        We have implemented administrative, technical, and physical safeguards to help prevent unauthorized access, use, or disclosure of your Personal Information. We follow generally accepted industry standards to protect the personal information submitted to us, both during transmission and once we receive it.
                        </li>
                        <li>
                        While we seek to protect your information to ensure that it is kept confidential, we cannot guarantee the security of any information. You should be aware that there is always some risk involved in transmitting information over the internet and that there is also some risk that others could find a way to thwart our security systems. As a result, while we strive to protect your Personal Information, we cannot ensure or warrant the security and privacy of your Personal Information or other content you transmit using the Apps, and you do so at your own risk. Thus, we encourage you to exercise discretion regarding the Personal Information you choose to disclose.
                        </li>
                    </ul>

                    <h3>CHANGES TO OUR PRIVACY POLICY</h3> 
                    
                    <ul>
                        <li>
                        We may revise this Privacy Policy from time to time, in our sole discretion, and the most current version will always be posted on our website (as reflected in the "Last Updated" heading). We encourage you to review this Privacy Policy regularly for any changes. By continuing to access or use the Apps after any revisions become effective, you agree to be bound by the revised Privacy Policy. If you do not agree to the new Privacy Policy, please stop accessing or using the Apps.
                        </li>
                    </ul>

                    <h3>How to Contact Us</h3>
                    <p style={{fontSize:'17px'}}> You can contact us about this privacy statement by writing or email us at the address below:</p>
                    <p>
                        MD-35 Eldeco Mansionz.<br />Sector 48, Sohna Road<br />Gurugram -
                        122018<br />
                        India<br />
                        E-mail:
                        <a href="mailto:pramit@louievoice.com">pramit@louievoice.com</a>
                    </p>

            </div>
        </div>
      </section>
      <OldLouieFooter/>
    </>
  )
};

export default OldLouiePrivacyPolicy;
