import React, { useEffect, useRef, useState } from "react";
import "./Section2.css";

const Section2 = () => {
  const [isVideoVisible, setIsVideoVisible] = useState(false);
  const [videoSrc, setVideoSrc] = useState("");
  const [hoveredPhone, setHoveredPhone] = useState(null);
  // const [clicked, setClicked] = useState(false);
  // const [muted, setMuted] = useState(true);
  const videoRef = useRef(null);
  // const louie_payments='https://firebasestorage.googleapis.com/v0/b/louievoicewebsite.appspot.com/o/International_payments%2Finternational%20payments%20rendition.mp4?alt=media&token=c8e2253e-ed23-4b60-8896-b097a3e3555c'
  const louie_payments='https://firebasestorage.googleapis.com/v0/b/louievoicewebsite.appspot.com/o/International_payments%2FInt%20Payment%20(1%20contact%20no%20note).mp4?alt=media&token=331dacd6-4fcc-4ba8-98e6-5deb38fb59e1'
  // useEffect(() => {
  //     console.log('inside use effect')
  //     const enableAudio = () => {
  //         if (videoRef.current) {
  //         videoRef.current.muted = false; // Unmute the video
  //         }
  //         document.removeEventListener("click", enableAudio);
  //         document.removeEventListener("touchstart", enableAudio);
  //     };

  //     document.addEventListener("click", enableAudio);
  //     document.addEventListener("touchstart", enableAudio);
  //     // handleHover(videoSrc,hoveredPhone)
  //     return () => {
  //         document.removeEventListener("click", enableAudio);
  //         document.removeEventListener("touchstart", enableAudio);
  //     };
  // }, [clicked]);
  // const handleClick=(src,phoneType)=>{
  //     setMuted(false)
  //     // console.log(muted)
  //     setClicked(!clicked)
  //     // console.log(clicked)
  //     // setVideoSrc(src);
  //     // setHoveredPhone(phoneType);
  
  //     // setTimeout(() => {
  //     // if (videoRef.current) {
  //     //     videoRef.current.muted = false; // Start muted to comply with autoplay policy
  //     //     videoRef.current.play().catch((error) => console.error("Playback failed:", error));
  //     // }
  //     // }, 100);
  // }
  // const handleHover = (src, phoneType) => {
  //     setVideoSrc(src);
  //     setHoveredPhone(phoneType);
  //     setTimeout(() => {
  //       if (videoRef.current) {
  //           console.log('section2',videoRef.current.muted)
  //         // videoRef.current.muted = true; // Start muted to comply with autoplay policy
  //         videoRef.current.muted = muted; //Do like this if you want to set muted to false for all the three section i.e. on only one click on any of the mobile view muted will false and next time when you hover over any other mobile it will directly play the audio
  //         videoRef.current.play().catch((error) => console.error("Playback failed:", error));
  //         }
  //     }, 100);
  // };

  // const handleMouseLeave = () => {
  //   if (videoRef.current) {
  //       videoRef.current.pause();
  //       videoRef.current.currentTime = 0;
  //   }
  //   setHoveredPhone(null);
  // };


  // New logic
  // pause if not mobile viewport
  const sectionRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (videoRef.current) {
          if (!entry.isIntersecting) {
            videoRef.current.pause(); // Pause the video when out of view
          }
        }
      },
      { threshold: 0.1 } // Trigger when at least 10% of the section is visible
    );

    if (sectionRef.current) {
      observer.observe(sectionRef.current);
    }

    return () => {
      if (sectionRef.current) {
        observer.unobserve(sectionRef.current);
      }
    };
  }, []);
  
  // Play video after state updates
  useEffect(() => {
    if (isVideoVisible && videoRef.current) {
      const playPromise = videoRef.current.play();
      if (playPromise !== undefined) {
        playPromise.catch((error) => console.error("Playback failed:", error));
      }
    }
  }, [isVideoVisible]);
  
  const handleHover = (src,phoneType) => {
  //   setVideoSrc(src);
  //   setIsVideoVisible(true);
  
  
  // setVideoSrc(src);
  // setHoveredPhone(phoneType);
  };
  
  const handleClick=(src,phoneType)=>{
      setVideoSrc(src);
      setIsVideoVisible(true);
      setHoveredPhone(phoneType);
  }
  
  const handleMouseLeave = () => {
    setIsVideoVisible(false);
    if (videoRef.current) {
      videoRef.current.pause();
      videoRef.current.currentTime = 0; // Reset video on exit
    }
  };

    
  return (
    <section className="section2">
      <div className="svg-background1">
        {/* <svg xmlns="http://www.w3.org/2000/svg" className="background-svg1" width="1440" height="576" viewBox="0 0 1440 576" fill="none">
          <path d="M384.774 80.6602C203.725 -10.3899 63.125 -7.3862 -0.973679 9.20429L1.77861 575.5L695.5 575.5L1441.5 575.5L1450.07 64.5346C1342.87 135.143 1068.48 312.121 869.714 308.065C621.258 302.996 611.085 194.473 384.774 80.6602Z" fill="url(#paint0_linear_466_363)"/>
          <defs>
            <linearGradient id="paint0_linear_466_363" x1="644" y1="-79" x2="641.845" y2="570.619" gradientUnits="userSpaceOnUse">
              <stop offset="0.000999756" stop-color="#FBFCFF"/>
              <stop offset="0.746" stop-color="#B8BEFF"/>
              <stop offset="0.976" stop-color="white"/>
            </linearGradient>
          </defs>
        </svg> */}

<svg xmlns="http://www.w3.org/2000/svg" className="background-svg1" width="1440" height="589" viewBox="0 0 1440 589" fill="none">
  <path d="M384.773 80.6601C203.725 -10.39 63.1246 -7.38631 -0.974103 9.20418L-0.973947 587L695.5 588.5L1442.5 588.5L1442.5 66.5C1335.3 137.108 1068.48 312.12 869.714 308.065C621.258 302.996 611.084 194.473 384.773 80.6601Z" fill="url(#paint0_linear_466_363)"/>
  <defs>
    <linearGradient id="paint0_linear_466_363" x1="644.001" y1="-79" x2="641.845" y2="570.619" gradientUnits="userSpaceOnUse">
      <stop offset="0.000999756" stop-color="#FBFCFF"/>
      <stop offset="0.746" stop-color="#B8BEFF"/>
      <stop offset="0.976" stop-color="white"/>
    </linearGradient>
  </defs>
</svg>
      </div>
      <h2 className="section2-title">See Louie in Action</h2>
      <div className="section2-content">
        {/* <div className="section2-text">
          
          <p>Keyboard Interface was the Past.</p>
        
          <p>Touch Interface is the Present.</p>
          
          <p>Voice Interface is the Future.</p>
          
          <h1 className="future-text">The Future is here!</h1>
        </div> */}

        <div className="section2-text">
          <p>
            Keyboard Interface was the <span className="underline">Past</span>.
          </p>
          <p>
            Touch Interface is the <span className="underline">Present</span>.
          </p>
          <p>
            Voice Interface is the <span className="underline">Future</span>.
          </p>
          <h1 className="future-text">The Future is here!</h1>
        </div>

        <div 
          ref={sectionRef}
          className="section2-image"
          onClick={()=>handleClick("/videos/louie.mp4", "louie")}
          onMouseEnter={() => handleHover("/videos/louie.mp4", "louie")}
          onMouseLeave={handleMouseLeave}
        >
          {hoveredPhone === "louie" && (
                <video ref={videoRef} src={louie_payments} autoPlay='true' controls  playsInline controlsList="nodownload nofullscreen noremoteplayback" disablePictureInPicture />
                // <iframe ref={videoRef} src={videoSrc} id="video-player" class="video" width="560" height="315"  frameborder="0" webkitallowfullscreen mozallowfullscreen allowfullscreen allow="autoplay"></iframe>
                // src="https://player.vimeo.com/video/309741585"
          )}
          {hoveredPhone!=='louie' && <img src="/images/sec2_image.png" alt="Louie App UI" />}
        </div>
      </div>
    </section>
  );
};

export default Section2;
